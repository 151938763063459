import React from 'react'
import "./styles.css"
import ProfileLogo from '../../assets/images/other/profile.svg'

export default function Loader() {
    return (
        <div className='loader-container'>
            <div className='loader-holder'>
                <img className='loader-logo' src={ProfileLogo} alt="" />
                <div className='text-animation'>
                    <div className='animate-frame'>
                        <p> Rotem Mizrahi </p>
                        <p> Server, Web & Mobile Development </p>
                        <p> Cloud Computing & IaC (Infrastructure as code) </p>
                        <p> Automations & Scripts </p>
                        <p> Applicative Infrastructure </p>
                        <p> Technical System Design </p>
                        <p> Blockchain Based development </p>
                        <p> Server, Web & Mobile Development </p>
                        <p> Cloud Computing & IaC (Infrastructure as code) </p>
                        <p> Automations & Scripts </p>
                        <p> Applicative Infrastructure </p>
                        <p> Technical System Design </p>
                        <p> Blockchain Based development </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
