import React, { useRef, useEffect } from 'react';
import './TrustedBy.css';

import BankLeumiLogo from '../../assets/images/recommendation/LeumiLogo.png'
import FlirLogo from "../../assets/images/recommendation/FLIR.jpg"
import CalLogo from "../../assets/images/recommendation/CalLogo.jpg";
import MinistryDefenseLogo from "../../assets/images/recommendation/MisitryDefenseLogo.png"
import NeswellLogo from "../../assets/images/recommendation/seekwellLogo.jpg";


// Dummy logos for illustration; replace with actual logo URLs
const logos = [
    BankLeumiLogo,
    FlirLogo,
    CalLogo,
    MinistryDefenseLogo,
    NeswellLogo,
];


const allLogos = [...logos, ...logos]

const TrustedBy = () => {
    const scrollRef = useRef(null);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (scrollRef.current) {
          const maxScrollLeft = scrollRef.current.scrollWidth / 2;
          if (scrollRef.current.scrollLeft >= maxScrollLeft) {
            scrollRef.current.scrollLeft = 0; // Reset scroll to start
          } else {
            scrollRef.current.scrollLeft += 2; // Scroll step in pixels
          }
        }
      }, 50); // Scroll speed in milliseconds
  
      return () => clearInterval(interval);
    }, []);
  
    return (
        <>
            <p className='p2 trusted-by'> Trusted by </p>
            <div className="trusted-by-wrapper">
                <div ref={scrollRef} className="scroll-container">
                {allLogos.map((logo, index) => (
                    <img key={index} src={logo} alt="Company logo" className="logo" />
                ))}
                </div>
            </div>
        </>
    );
  };
  
  export default TrustedBy;