import React from 'react'
import "./styles.css"
import R from '../../assets/images/R.svg'

export default function Hero() {

    return (
        <div className='flex justify-center'>
            <div className='lg:w-[1200px] w-full h-auto relative'>
                <div>
                    <img className='desktop-hero-img' src={require('../../assets/images/hero-static-asset.png')} alt="" />
                    <img className='mobile-hero-img' src={require('../../assets/images/Mo-bubble.png')} alt="" />
                </div>
                <div className='hero-text'>
                    
                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", marginTop: "-50px" }}>
                        <img className='r-svg' src={R} alt="rotem" />
                        <span className='greenLogoText' > otech Solutions </span>
                    </div>
                    <p className='p1' data-text=" Software Consultant Agency"> Software Consultant Agency </p>
                </div>
            </div>
        </div>
    )
}
