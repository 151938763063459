import 'swiper/css';
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import ArrowSvg from '../../assets/icons/ArrowSvg'
import ProfileSvg from '../../assets/images/other/profile.svg'
import BankLeumiLogo from '../../assets/images/recommendation/LeumiLogo.png'
import FlirLogo from "../../assets/images/recommendation/FLIR.jpg"
import CalLogo from "../../assets/images/recommendation/CalLogo.jpg";
import MinistryDefenseLogo from "../../assets/images/recommendation/MisitryDefenseLogo.png"
import NeswellLogo from "../../assets/images/recommendation/seekwellLogo.jpg";

const data = [
    {
        owner: 'Dor Zilka ( Head Of Digital Development )',
        title: 'Cal. (Israel Credit Cards) ',
        section: 'Cloud Transformation & IaC',
        image: CalLogo,
        description: `
        "Rotem is an exceptional consultant, possessing a unique combination of expertise and ingenuity in the realm of IT. Our collaboration with Rotem has been a truly rewarding experience, as he has adeptly addressed a diverse range of topics, including cloud infrastructure, Infrastructure as Code (IaC) management, and web development.
        His involvement spans the entire project lifecycle, from crafting robust and scalable architectures to overseeing the meticulous execution of the development process. At each juncture, Rotem consistently delivers exceptional value, exceeding expectations as a knowledgeable and insightful advisor."
        `,
    },
    {
        owner: 'Sara Shulman ( Team Leader )',
        image: BankLeumiLogo,
        title: 'Bank Leumi',
        section: 'Web & Mobile Consultant',
        description: `
"...Grasping our present circumstances and long-term objectives, Rotem meticulously crafted an adaptive architecture tailored to accommodate both the immediate requirements and future growth of our project.
Rotem's unique strength lies in the ability to envision the overarching strategy while constructing an appropriate model, as well as immersing in the design and technical intricacies.
We not only received a customized and precise model but also benefited from hands-on assistance in every practical aspect of the code implementation.
Rotem epitomizes professionalism, displaying dedication to the project and fostering exceptional interpersonal relations..."
        `,
    },
    {
        owner: 'Yair Sherf ( R&D Lead )',
        title: 'FLIR',
        section: 'Lecturing & Workshops',
        image: FlirLogo,
        description: `
        "Rotem is a highly motivated and passionate individual who possesses an extensive knowledge of React and its related technologies. He has a talent for explaining complex concepts in a simple and understandable manner, which is essential in any teaching environment. He is patient and understanding, making him an excellent communicator and an effective instructor..."
        `,
    },
    {
        owner: 'Dorin Lev ( Team Leader )',
        title: 'Israel Ministry of Defense',
        section: 'Web & Mobile Consultant',
        image: MinistryDefenseLogo,
        description: `
        "...his technical and problem-solving skills are truly extraordinary. He consistently demonstrates an unparalleled ability to identify and solve complex problems, often going above and beyond what is required to ensure that our project and team are successful. Additionally, his work is always thorough and organized. Rotem's ability to provide professional opinions and practical solutions to complex of vague problems is great. He is an expert in his field, and his extensive technical knowledge allows him to identify and implement the most effective solutions to even the most difficult technical challenges..."
        `,
    },
    // {
    //     owner: 'Assaf Lamn ( Project Manager )',
    //     title: 'Neswell (Seekwell)',
    //     section: 'Web & Mobile Consultant',
    //     image: NeswellLogo,
    //     description: `
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"
    //     `,
    // },
]

export default function Recomendation() {
    const [swiper, setSwiper] = React.useState(null);
    const [activeSliderIndex, setActiveSlider] = React.useState(0);

    return (
        <div className='mt-[100px] lg:mt-[200px]'>
            <div className='flex'>
                <div className='flex-1'>
                    <div className='opacity-75 text-10 lg:text-16 uppercase font-medium'>Clients</div>
                    <div className='mt-10 lg:mt-15 text-26 lg:text-[52px] font-bold'>Recommendations</div>
                    <div className='mt-10 lg:mt-20 text-12 lg:text-16 font-normal lg:hidden'>What people are saying about our skills and expertise.</div>
                </div>
                <div className='flex-1 hidden lg:block'></div>
            </div>
            <div className='lg:flex justify-between align-between hidden'>
                <div className='font-normal'>What people are saying about our skills and expertise.</div>
                <div className='flex gap-[49px]'>
                    <span onClick={() => swiper.slidePrev()}> <ArrowSvg color={activeSliderIndex <=0 ? '#52525B' : '#FFFFFF'} className="cursor-pointer" /> </span>
                    <span onClick={() => swiper.slideNext()}> <ArrowSvg color={activeSliderIndex+1 >= data.length ? '#52525B' : '#FFFFFF'} className="cursor-pointer rotate-180" /></span>
                </div>
            </div>

            <div className='mt-50'>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    onSlideChange={(s) => setActiveSlider(s.activeIndex) }
                    onSwiper={(s) => { setSwiper(s); }}>

                    {
                        data.map((item, i) => (
                            <SwiperSlide virtualIndex={i}>
                                <div className='blur-box p-30 lg:h-[367px] lg:flex justify-center items-center lg:pl-[100px] relative overflow-hidden'>
                                    <div className='lg:flex items-center justify-between'>
                                        <div className='lightBorder-right border-none lg:border-r-[1px] lg:border-solid lg:pr-[100px] flex-[6]'>
                                            <div className='text-16 font-normal text-justify'>{item.description}</div>
                                            <div className='text-16 font-bold mt-20 lg:mt-40'>{item.owner}</div>
                                        </div>
                                        <div className='border-t-[1px] mt-20 lg:mt-0 border-t-[#ffffff1a] lg:!border-none flex items-center justify-start lg:justify-between flex-[2] lg:pl-[100px] pt-20'>
                                            <div className='flex items-center'>
                                                <img className='w-60 h-60 lg:w-100 lg:h-100' src={item.image || ProfileSvg} alt="profile" />
                                                <div className='ml-24'>
                                                    <div className='text-16 font-bold'>{item.title}</div>
                                                    <div className='text-16 font-normal text-[#A1A1AA] mt-10'>{item.section}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='w-[270px] h-[270px] blur-[300px] absolute rotate-[180deg] bottom-24 left-[600px] cyanTopPurple hidden lg:block' />
                                    <div className='w-[270px] h-[270px] blur-[300px] absolute lg:rotate-[180deg] top-[294px] lg:top-25 left-[-30px] lg:left-[unset] lg:right-8 skytogreen' /> */}
                                    <img className='absolute hidden lg:block' src={require('../../assets/images/recommendation/center-flare.png')} alt="" />
                                    <img className='absolute' style={{right: 0, marginRight: -50}} src={require('../../assets/images/recommendation/right-flare.png')} alt="" />
                                    {/* <img className='absolute' style={{right: 0, marginRight: -50}} src={require('../../assets/images/recommendation/mobile-flare-4.png')} alt="" /> */}
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <div className='flex gap-[49px] justify-center pt-30'>
                <span onClick={() => swiper.slidePrev()}> <ArrowSvg color={activeSliderIndex <=0 ? '#52525B' : '#FFFFFF'} className="cursor-pointer" /> </span>
                <span onClick={() => swiper.slideNext()}> <ArrowSvg color={activeSliderIndex+1 >= data.length ? '#52525B' : '#FFFFFF'} className="cursor-pointer rotate-180" /></span>
            </div>

        </div>

    )
}
