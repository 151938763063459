import React from 'react'
import "./styles.css"
import Logo from '../../logo.svg'
import EmailSvg from '../../assets/icons/emai.svg'

export default function Footer() {
    return (
        <div className='lg:pl-[100px] lg:pr-[80px] container-footer lg:justify-between lg:flex-row flex-col flex items-center'>
            <img className='cursor-pointer pt-[77px] lg:pt-0' src={Logo} alt="rotem" />
            <div className='font-normal text-16 mt-40 lg:mt-0 lg:text-18'>Any Product Can Be Improved</div>
            <div className='flex mt-[35px] lg:mt-0'>
                <img src={EmailSvg} alt="email" />
                <div className='ml-18 font-normal text-18'>
                r.mizrahi@rotechsolutions.io
                </div>
            </div>
        </div>
    )
}
