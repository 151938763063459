import './App.css';
import Loader from './components/loader/Loader'
import LandingPage from './pages/LandingPage';
import React, { useState, useEffect } from 'react';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);
  });

  //do routing when other pages available
  return (
    <>
      {
        loading ? (
          <Loader />
        ) : (
          <></>
        )
      }
        <LandingPage />
    </>
  );
}

export default App;
