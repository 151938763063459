import React from 'react'
import Footer from '../components/footer/Footer'
import GetInTouch from '../components/get_in_touch/GetInTouch'
import Header from '../components/header/Header'
import Hero from '../components/hero/Hero'
import Operations from '../components/operations/Operations'
import Recomendation from '../components/recomendation/Recomendation'
import Services from '../components/services/Services'
import TrustedBy from '../components/trusted_by/TrustedBy'

export default function LandingPage() {
  return (
    <>
      <div className='p-30 lg:pl-[100px] lg:pt-[50px] lg:pr-[80px]'>
        <Header />
        <Hero />
        <TrustedBy />
        <Services />
        <Operations />
        <Recomendation />
        <GetInTouch />
      </div>
      <Footer />
    </>
  )
}
