import './styles.css'
import "swiper/css";
import "swiper/css/effect-cards";

import React, { useState } from 'react'
import SquareArraow from '../../assets/icons/squareArrow.svg'
import DefineGif from '../../assets/images/other/define.gif'
import GrowthGif from '../../assets/images/other/growth.gif'
import LecturGif from '../../assets/images/other/lecture.gif'
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";


const data = [
    {
        title: 'Development Process Management',
        description: 'Collaborate with our team of experts to establish and lead goal-oriented development processes that deliver results. We work closely with your organization to define and manage development processes that align with your objectives, ensuring that your projects are completed on time, within budget, and with high-quality standards. Our experts provide ongoing support and guidance, helping your team navigate challenges and adapt to changing requirements.',
        image: DefineGif

    },
    {
        title: 'Developer Growth & Skill Enhancement',
        description: "Invest in your team's professional development with our comprehensive training, mentorship, and guidance program. Our growth program for developers is designed to enhance their skills, increase their knowledge, and foster a culture of continuous learning within your organization. Through a combination of training sessions, workshops, and one-on-one mentorship, we help your developers grow professionally and contribute to your organization's success.",
        image: GrowthGif

    },
    {
        title: 'Technology Lectures & Industry Insights',
        description: 'Stay informed and adapt to the changing tech landscape through our engaging lectures and expert guidance on emerging trends and best practices. Our team of professionals shares insights on the latest technologies, industry standards, and innovative approaches to help your organization stay competitive in a rapidly evolving tech environment. We offer a range of lecture topics and formats, ensuring that your team remains up-to-date and equipped to handle the challenges',
        image: LecturGif
    },
]

export default function Operations() {
    const [selected, setSelected] = useState(0)
    return (
        <div className='mt-[100px]' style={{ paddingBottom: 50 }}>
            <div className='pt-[100px] lg:pt-[200px] pb-50 lg:pb-[100px] relative'>
                {/* skyblue-flare.png */}
                <img className='absolute' style={{marginLeft: -100, marginTop: -400}}  src={require('../../assets/images/experties/skyblue-flare.png')} alt="" />
                <img className='absolute hidden lg:block' style={{marginLeft: 50, marginTop: -400}}  src={require('../../assets/images/experties/cyan-purple-flare.png')} alt="" />
                <div className='flex'>
                    <div className='flex-1'>
                        <div className='opacity-75 text-10 lg:text-16 uppercase font-medium'>Services</div>
                        <div className='mt-10 lg:mt-15 text-26 lg:text-[52px] font-bold'>Operational Expertise:</div>
                        <div className='mt-10 lg:mt-20 text-12 lg:text-16 font-normal'>Streamlining Processes for Sustainable Growth</div>
                    </div>
                    <div className='flex-1 hidden lg:block'></div>
                </div>
            </div>
            <div>
                <div className='lg:flex hidden gap-40 relative'>
                    <img className='absolute' style={{right: 0, marginTop: -500}} src={require('../../assets/images/experties/number-3-flare.png')} alt="" />
                    {
                        data.map((item, i) => (<div onClick={() => i !== selected && setSelected(i)} key={item.title} className='flex-1 opt-box pl-30 flex h-[214px] relative cursor-pointer'>
                            <div className='flex items-center'>
                                <img className='w-30 h-30 mr-20' src={SquareArraow} alt="square" />
                                <div className='font-bold text-16 w-3/5'>
                                    {item.title}
                                </div>
                            </div>
                            <span className='number'>{i + 1}</span>
                        </div>))
                    }
                </div>
                <div className='lg:flex hidden gap-40'>
                    <div className={`bottom-strip ${selected === 0 && ' activeStrip'}`}></div>
                    <div className={`bottom-strip ${selected === 1 && ' activeStrip'}`}></div>
                    <div className={`bottom-strip ${selected === 2 && ' activeStrip'}`}></div>
                </div>
                <div className='bottom-strip activeStrip !h-[473px] lg:flex items-center hidden relative overflow-hidden'>
                    <div className='flex-1 px-[100px]'>
                        <div className='flex items-center'>
                            <img className='w-30 h-30 mr-20' src={SquareArraow} alt="square" />
                            <div className='font-bold text-16'>
                                {data[selected].title}
                            </div>
                        </div>
                        <div className='mt-20 text-16 font-normal'>
                            {data[selected].description}
                        </div>
                    </div>
                    <div className='flex-1'>
                        <img className='h-[473px] mix-blend-lighten w-full' src={data[selected].image} alt={data[selected].title} />
                    </div>
                    <img className='absolute' style={{ marginLeft: 200, marginTop: 200}} src={require('../../assets/images/experties/center-flare.png')} alt="" />
                </div>
                <div className=' w-full lg:hidden relative'>
                    <Swiper
                        //  effect={"coverflow"}
                        //  grabCursor={true}
                        //  slidesPerView={"auto"}
                        //  coverflowEffect={{
                            //    rotate: 50,
                            //    stretch: 0,
                            //    depth: 100,
                            //    modifier: 1,
                            //    slideShadows: true,
                            //  }}
                            //  pagination={true}
                        centeredSlides={true}
                        effect={"cards"}
                        grabCursor={true}
                        modules={[EffectCards]}
                        className="operationsSwiper"
                    >
                        {
                            data.map((item, i) => (<SwiperSlide style={{ minHeight: 500 }} key={i}>
                                <div className='w-full relative'>
                                    <div className='pl-30 pt-37 pr-25 mobileBox activeStrip h-full w-full'>
                                        <div>
                                            <div className='flex items-center'>
                                                <img className='w-18 h-18 mr-10' src={SquareArraow} alt="square" />
                                                <div className='font-bold text-16 whitespace'>
                                                    {item.title}
                                                </div>
                                            </div>
                                            <div className='mt-10 text-14 font-normal'>
                                                {item.description}
                                            </div>
                                        </div>
                                        <img className='h-[244px] w-full mix-blend-lighten' src={item.image} alt={item.title} />
                                    </div>
                                </div>
                            </SwiperSlide>))
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
