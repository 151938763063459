import React, { useState } from 'react'
import Expertise from '../../assets/icons/Expertise.png'
import Operational from '../../assets/icons/Operational.png'
import Other from '../../assets/icons/Other.png'

const data = [
    {
        name: 'Expertise',
        icon: Expertise
    },
    {
        name: 'Operational',
        icon: Operational
    },
    {
        name: 'Other',
        icon: Other
    },
]

export default function GetInTouch() {

    const [selected, setSelected] = useState()

    return (
        <div id='get-in-touch' className='mt-[100px] lg:mt-[200px] mb-[100px]'>
            <div className='flex'>
                <div className='flex-1'>
                    <div className='opacity-75 text-10 lg:text-16 uppercase font-medium'>Contact</div>
                    <div className='mt-10 lg:mt-15 text-26 lg:text-[52px] font-bold'>Get in touch</div>
                    <div className='mt-10 lg:mt-20 text-12 lg:text-16 font-normal'>Need to get in touch?</div>
                </div>
                <div className='flex-1 hidden lg:block'></div>
            </div>
            <div className='mt-50 lg:flex lg:mt-[100px] gap-[54px] relative'>
                {/* <div className='w-[270px] h-[270px] absolute top-[87px] right-[-90px] cyanTopPurple lg:hidden' /> */}
                <img className='absolute lg:hidden right-0 -ml-[30px] -mt-[230px]' src={require('../../assets/images/get-in-touch/flare-1.png')} alt="" />

                {/* <div className='w-[270px] h-[270px] absolute bottom-[-130px] left-[-30px] skytogreen lg:hidden' /> */}
                <img className='absolute lg:hidden' src={require('../../assets/images/get-in-touch/flare-2.png')} alt="" />
                <div className='w-full lg:w-2/5'>
                    <div>
                        <div className='text-14 font-medium'>Choose Subject</div>
                        {
                            data.map((item, i) => (<div key={item.name} onClick={() => setSelected(i)} className='py-10 pr-32 lg:py-12 lg:pl-15 lg:pr-22 flex justify-between border-box-light cursor-pointer items-center mt-8'>
                                <div className='flex items-center'>
                                    <img className='w-40 h-40 lg:w-60 lg:h-60' src={item.icon} alt="Expertise" />
                                    <div className={`ml-15 font-normal text-12 lg:text-16 ${selected !== i && 'text-[#60606B]'} `}>{item.name}</div>
                                </div>
                                <input checked={selected === i} name='subject' onChange={e => setSelected(i)} className='common-radio' type="radio" />
                            </div>))
                        }
                    </div>
                    <button className='bg-[#111013] px-60 py-13 text-16 font-normal mt-[75px] outline-none border-0 text-white hidden lg:block'>Send</button>
                </div>
                <div className='w-full lg:w-3/5'>
                    <div className='lg:flex gap-40'>
                        <div className='flex flex-col flex-1 mt-20 lg:mt-0'>
                            <label className='text-14 font-medium' htmlFor="name">Name (required)</label>
                            <input className='border-box-light outline-none p-10 pl-16 text-16 font-normal mt-8 bg-transparent' placeholder='Your name' type="text" id="name" />
                        </div>
                        <div className='flex flex-col flex-1 mt-20 lg:mt-0'>
                            <label className='text-14 font-medium' htmlFor="name">Company (optional)</label>
                            <input className='border-box-light outline-none p-10 pl-16 text-16 font-normal mt-8 bg-transparent' placeholder='Your company name' type="text" id="name" />
                        </div>
                    </div>
                    <div className='lg:flex gap-40 mt-24'>
                        <div className='flex flex-col flex-1 mt-20 lg:mt-0'>
                            <label className='text-14 font-medium' htmlFor="name">Role</label>
                            <input className='border-box-light outline-none p-10 pl-16 text-16 font-normal mt-8 bg-transparent' placeholder='Product Manager' type="text" id="name" />
                        </div>
                        <div className='flex flex-col flex-1 mt-20 lg:mt-0'>
                            <label className='text-14 font-medium' htmlFor="name">Email (required)</label>
                            <input className='border-box-light outline-none p-10 pl-16 text-16 font-normal mt-8 bg-transparent' placeholder='Your email' type="text" id="name" />
                        </div>
                    </div>
                    <div className='flex flex-col mt-20 lg:mt-24'>
                        <label className='text-14 font-medium' htmlFor="name">Message (required)</label>
                        <textarea rows={3} className='w-full border-box-light outline-none p-10 pl-16 text-16 font-normal mt-8 bg-transparent' placeholder='Brief project details' type="text" id="name" />
                    </div>
                    <button className='lg:hidden mt-26 bg-[#111013] px-60 py-13 text-16 font-normal outline-none border-0 text-white'>Send</button>
                </div>
            </div>
        </div>
    )
}
