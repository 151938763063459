import React from 'react';
import Logo from '../../logo.svg'
import "./style.css"

export default function Header() {
    return (
        <div className='flex items-center justify-between'>
            <img className='cursor-pointer w-[50px]' src={Logo} alt="rotem" />
            <div className='items-center flex justify-between lg:gap-[52px] gap-30'>
                <div className='cursor-pointer lg:text-15 text-10 font-medium'>
                    <a className='header-link' href="#services">Services</a>
                </div>
                <div className='cursor-pointer lg:text-15 text-10 font-medium header-link'>
                    <a className='header-link' href="#get-in-touch">Contact</a>
                </div>
            </div>
        </div>
    )
}