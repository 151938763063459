import React, { useState } from 'react'
import './styles.css';
import SquareArrow from '../../assets/icons/squareArrow.svg'
import PlusIcon from '../../assets/icons/plus.svg'
import MinusIcon from '../../assets/icons/minus.svg'
import AppGif from '../../assets/images/services/app.gif'
import AutoGif from '../../assets/images/services/auto.gif'
import BlockGif from '../../assets/images/services/block.gif'
import CCgif from '../../assets/images/services/cc.gif'
import TechGif from '../../assets/images/services/tech.gif'
import WebGif from '../../assets/images/services/web.gif'

const data = [
    {
        title: 'AI Driven Development',
        description: "Our 'AI-Driven Development' service is a groundbreaking advancement in business technology, integrating state-of-the-art Large Language Models (LLMs), Transformer architectures, and a suite of sophisticated, proprietary AI solutions. This combination facilitates the creation of intelligent applications and comprehensive automation strategies, fundamentally transforming all aspects of business operations. By leveraging LLMs for advanced natural language processing and Transformer models for efficient sequential data handling, our service provides a robust platform for predictive analytics, cognitive automation, and intelligent decision support. This cohesive ecosystem, designed for operational excellence and innovation, propels businesses towards a competitive edge, embodying our commitment to redefining the potential of business technology.",
        icon: BlockGif
    },
    {
        title: 'Comprehensive Web & Mobile Development',
        description: 'Harness the power of modern web and mobile applications through our extensive expertise in development and integration. Our team ensures that your applications are built using the latest technologies, offering a seamless user experience across platforms. We focus on achieving applications that are scalable, optimized for performance, and easily adaptable to meet the ever-evolving demands of the digital landscape.',
        icon: WebGif
    },
    {
        title: 'Cloud Transformation & IaC Adoption',
        description: "Optimize your organizations cloud operations with our proven experience in cloud computing and Infrastructure as Code implementation. Our experts guide you through the process of transitioning to the cloud, helping you reduce costs, streamline deployment, and improve overall efficiency. We also provide strategic advice on adopting Infrastructure as Code practices, allowing you to manage and maintain your infrastructure with ease and precision.",
        icon: CCgif
    },
    {
        title: 'Intelligent Automation & Scripting',
        description: 'Leverage our know-how in creating tailored automation and scripting solutions to enhance efficiency and streamline workflows. We analyze your existing processes and identify opportunities for automation, reducing manual tasks and the potential for human error. Our team of experts develops custom scripts and tools to automate repetitive tasks, optimize system performance, and improve productivity across your organization.',
        icon: AutoGif
    },
    {
        title: 'Applicative Infrastructure',
        description: "Ensure your system's long-term flexibility and maintainability with our strategic approach to applicative infrastructure planning. We design core application modules that adhere to strict conventions, design patterns, and restrictions, allowing your system to scale effectively and remain adaptable to change. ",
        icon: AppGif
    },
    {
        title: 'Technical System Design',
        description: "Achieve your organizational objectives with our personalized technical system design services, tailored to your unique requirements. Our team of experts collaborates with you to understand your goals and create custom solutions that support your organization's growth and success.",
        icon: TechGif
    },
]

export default function Services() {
    const [selected, setSelected] = useState(0)
    return (
        <div className='service-container' id='services'>
            <div className='flex'>
                <div className='flex-1'>
                    <div className='opacity-75 text-10 lg:text-16 uppercase font-medium'>Services</div>
                    <div className='mt-10 lg:mt-15 text-26 lg:text-[52px] font-bold'>Technical Expertise</div>
                    <div className='mt-10 lg:mt-20 text-12 lg:text-16 font-normal'>Advanced Technical Solutions and Services</div>
                </div>
                <div className='flex-1 hidden lg:block'></div>
            </div>
            <div className='flex gap-12 mt-50 lg:mt-[100px]'>
                <div className="tabs mb-10 flex-1 relative">
                    <img className='absolute lg:hidden' style={{right: 0, marginTop: -500}} src={require('../../assets/images/services/mobile-flare.png')} alt="" />
                    {/* <div className='w-[196px] h-[196px] absolute top-[487px] right-[-15px] cyanTopPurple lg:hidden' /> */}
                    {
                        data.map((item, i) =>
                            <div style={{ zIndex: 3, position: "inherit" }} key={item.title} className="tab mb-20 lg:mb-10">
                                <input checked={i === selected} onChange={(e) => selected !== i ? setSelected(i) : setSelected(-1) } className='input-radio' type="radio" id={`rd${i}`} name="rd" />
                                <label className="tab-label p-20 pr-24 flex items- justify-between" htmlFor={`rd${i}`}>
                                    <div className='flex items-center'>
                                        <img src={SquareArrow} alt="icon" />
                                        <div className='text-12 lg:text-16 font-bold ml-15'>{item.title}</div>
                                    </div>
                                    <img src={i === selected ? MinusIcon : PlusIcon} alt="plus" />
                                </label>
                                <div className="tab-content lg:text-16 lg:font-normal text-12">
                                    <img className='lg:hidden m-auto' src={item.icon} alt="icon" />
                                    <div>{item.description}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='flex-1 justify-center items-center mt-[-100px] hidden lg:flex'>
                    {
                        data.map((item, i) => i === selected && <img key={item.description} src={item.icon} alt="icon" />)
                    }
                </div>
            </div>
        </div>
    )
}
